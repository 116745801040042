import React from 'react'
import { WhileIO, useSecureLookup, Pending, Failure, Success } from 'react-hook-oauth2'
import { None, List } from 'components/History'
import { Issue } from 'components/core/Issue'

const HOST = process.env.REACT_APP_API

const IO = WhileIO(
  Pending(None),
  Failure(Issue),
  Success(({ content, updateTab }) => <List builds={content} updateTab={updateTab} />),
)

export default ({ sourcecode, updateTab }) => {
  const { status } = useSecureLookup(`${HOST}/sourcecode/history/${encodeURIComponent(sourcecode.id)}`)

  // TODO: reverse from backend
  return (<IO status={status.map(x => x.reverse())} updateTab={updateTab} />)
}