import React from 'react'
import { useSecureLookup } from 'react-hook-oauth2'
import { SeqSourceCode } from 'components/SourceCode'

const HOST = process.env.REACT_APP_API

export default () => {
  const { status } = useSecureLookup(`${HOST}/sourcecode`)

  return (
    <>
      <SeqSourceCode status={status} />
    </>
  )
}