import React from 'react'
import { NonIdealState, Button, Intent, Code, Text } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { withRouter } from 'react-router-dom'
import { signup } from 'toolkit/account'

const Unauthorized = () => (
  <NonIdealState
    icon={IconNames.USER} 
    title="Your session is expired!"
    description={`Please sign-in to service again!`}
    action={
      <Button
        large
        intent={Intent.PRIMARY}
        icon={IconNames.USER}
        onClick={signup}
      >
        Sign-In!
      </Button>}
  />
)

const NotFound = withRouter(({ history }) => (
  <NonIdealState 
    icon={IconNames.DOCUMENT} 
    title="Not Found!"
    description={`
      We are not able to find requested content.
    `}
    action={
      <Button
        large
        intent={Intent.PRIMARY}
        icon={IconNames.UNDO}
        onClick={() => history.goBack()}
      >
        Go Back!
      </Button>
    }
  />
))

const Unknown = ({ reason }) => (
  <NonIdealState 
    icon={IconNames.ERROR} 
    title="Ops, Sorry!"
    description={
      <Text>
        We are not able to fullfil you request!
        <br/>
        Please try again later!<br/><br/>
        Ask help from support channel using reference code <Code>{reason.instance}</Code>
      </Text>}
  />
)

export default ({ status: { reason } }) => {
  switch (reason.type) {
    case 'https://httpstatuses.com/401':
      return (<Unauthorized />)

    case 'https://httpstatuses.com/404':
      return (<NotFound />)

    default:
      return (<Unknown reason={reason}/>)
  }
}