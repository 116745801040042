import React from 'react'
import { Flex, Box } from 'reflexbox'
import { Card, ProgressBar, Intent } from '@blueprintjs/core'
import { Ikons as Status, Label } from 'components/core/Status'

export default ({ build, suites }) => {
  let passed = 0
  let failed = 0
  suites.forEach(x => {
    passed = passed + x.passed
    failed = failed + x.failed
  })
  const total = failed + passed
  const status = failed > 0 ? 'failure' : 'success'

  return (
    <Card>
      <Flex>
        <Box width={[3/12]}>
          <Status value={build.status}>
            &nbsp;
            <Label value={status} />:
            &nbsp;
            {failed > 0 ? failed : passed} of {total}
          </Status>
        </Box>
        <Box width={[9/12]}>
          <ProgressBar
            value={total === 0 ? undefined : passed / total}
            animate={build.status === 'pending'}
            stripes={build.status === 'pending'}
            intent={total === 0 ? Intent.WARNING : Intent.SUCCESS}
          />
        </Box>
      </Flex>
    </Card>
  )
}