import React from 'react'
import { WhileIO, Success, Pending, Failure } from 'react-hook-oauth2'
import { Spinner, Intent } from '@blueprintjs/core'
import { Flex } from 'reflexbox'
import { Issue } from 'components/core/Issue'
import Header from './Header'
import Nav from './Nav'
import './SourceCode.css'

const None = () => (
  <Flex style={{
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh',
  }}>
    <Spinner size={70} intent={Intent.PRIMARY}/>
  </Flex>
)

const UI = ({ content: props }) => {
  return (
  <div className="sourcecode">
    <Header {...props} />
    <Nav {...props} />
  </div>
  )
}

export default WhileIO(
  Pending(None),
  Success(UI),
  Failure(Issue),
)
