import React from 'react'
import { NonIdealState, Button, Intent, Card } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { withRouter } from 'react-router-dom'

export default withRouter(
  ({ history }) => (
    <Card elevation={2}>
      <NonIdealState
        icon={IconNames.GIT_REPO} 
        title="Let's get you started!"
        description={`
          Assay It makes a formal quality proof of your application.
          It supports testing of open and closed source software hosted at 
          software development version control using Git.`}
        action={
          <Button
            large
            intent={Intent.PRIMARY}
            icon={IconNames.ADD}
            onClick={() => history.push('/config/sourcecode')}
          >
            Add Repository
          </Button>
        }
      />
    </Card>
  )
)
