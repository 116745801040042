import React from 'react'
import { H4, Classes, Tag, Callout, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { WhoIs } from 'react-hook-oauth2/lib/hoc'
import Grant from './Grant'
import Unlink from './Unlink'

const UI = ({ username, scopes }) => (
  <div className={[Classes.RUNNING_TEXT, Classes.TEXT_LARGE].join(' ')} >
    <H4>GitHub Account</H4>
    <p>You are currently linked to <Tag>{username && username.split(':')[1]}</Tag> on GitHub.</p>
    <Callout
      title="GitHub permission"
      icon={IconNames.ENDORSED}
      intent={Intent.SUCCESS}
    >
      Assay IT uses the following GitHub permissions to operate on your behalf.
      Read more about scopes on &nbsp;
      <a href="https://docs.github.com/en/developers/apps/scopes-for-oauth-apps">
        GitHub Docs.
      </a>
    </Callout>
    <Grant scopes={scopes} />

    <p>&nbsp;</p>
      <Callout
        intent={Intent.DANGER}
        title="Danger Zone"
      >
        Following actions cannot be undone!
      </Callout>
      <Unlink />
  </div>
)

export default () => WhoIs(UI)