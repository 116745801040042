import React, { useState, useEffect } from 'react'
import { useSecureCreate, WhileIO, Success, Pending, Unknown } from 'react-hook-oauth2'
import { Button, Intent, Callout, Code, ProgressBar, Dialog, Classes, Label } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

const HOST = process.env.REACT_APP_API

const KeyName = ({ updateKeyId }) => (
  <div className="bp3-input-group bp3-fill">
    <Label>
      What’s this secret key for?
      <input
        type="text"
        className="bp3-input"
        placeholder="Key name"
        onChange={(e) => updateKeyId(e.target.value)}
      />
    </Label>
  </div>
)

const KeyNameCtrl = ({ keyId, commitKeyId }) => (
  <Button
    intent={Intent.PRIMARY}
    disabled={keyId.length === 0}
    onClick={() => commitKeyId(keyId)}
  >
    Generate
  </Button>
)

const KeyWait = () => (
  <>
    <p>
      <strong>Please wait!</strong>&nbsp;
      We are crafting a strong secret for you.&nbsp;
      It might take a few seconds...
    </p>
    <ProgressBar intent={Intent.PRIMARY} />
  </>
)

const KeyShow = ({ content }) => (
  <>
    <Callout intent={Intent.WARNING}>
      <p>
        This is the&nbsp;<strong>only</strong>&nbsp;time that the secret access keys can be
        viewed. You cannot recover it later. However, you can create new access keys at any time.
      </p>
    </Callout>  
    <br/>
    <p>
      <Code style={{ overflowWrap: 'break-word', display: 'inline-block', width: '100%' }}>{content[1]}</Code>
    </p>
  </>  
)

const KeyShowCtrl = ({ updateState }) => (
  <Button
    intent={Intent.PRIMARY}
    onClick={() => updateState(false)}
  >
    Ok
  </Button>
)


const IO = WhileIO(
  Unknown(KeyName),
  Pending(KeyWait),
  Success(KeyShow),
)

const IOCtrl = WhileIO(
  Unknown(KeyNameCtrl),
  Success(KeyShowCtrl),
)

export default ({ append }) => {
  const [ state, updateState ] = useState(false)
  const [ keyId, updateKeyId ] = useState('')
  const { status, commit } = useSecureCreate(`${HOST}/account/access`)
  const close = () => {
    updateState(false)
    commit(undefined)
    updateKeyId('')
  }

  useEffect(() => {
    status.onSuccess(x => append({ id: x[0] }))
    // eslint-disable-next-line
  }, [status])

  return (
    <>
      <Button
        intent={Intent.PRIMARY}
        icon={IconNames.ADD}
        onClick={() => updateState(true)}
      >
        New Access Key
      </Button>

      <Dialog
        icon={IconNames.KEY}
        title={`New Access Key: ${keyId}`}
        canEscapeKeyClose
        canOutsideClickClose={false}
        isOpen={state}
        onClose={close}
      >
        <div className={Classes.DIALOG_BODY}>
          <IO
            status={status}
            updateKeyId={updateKeyId}
          />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <IOCtrl
              status={status}
              keyId={keyId}
              commitKeyId={commit}
              updateState={close}
            />
          </div>
        </div>
      </Dialog>
    </>
  )
}
