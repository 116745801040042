import React from 'react'
import { Ikons as Status } from 'components/core/Status'
import { HTMLTable, Text } from '@blueprintjs/core'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { Empty } from 'components/core/Issue'

const Build = ({ status, pr, title, updated, head, onClick }) => (
  <tr className={`chrn-interactive-${status}`} onClick={onClick}>
    <td><Text ellipsize><Status value={status}>&nbsp;#{pr}</Status></Text></td>
    <td><Text ellipsize>{head && head.split(/^\[|\]$/)[1].split('/').reverse()[1]}</Text></td>
    <td><Text ellipsize>{head && head.split(/^\[|\]$/)[1].split('/').reverse()[0].substring(0,7)}</Text></td>
    <td><Text ellipsize>{moment(updated).fromNow()}</Text></td>
    <td><Text ellipsize>{title}</Text></td>
  </tr>
)

export default withRouter(({ builds, history, updateTab }) => (
  <>
  {builds.length === 0 && <Empty />}

  <HTMLTable interactive>
    <colgroup>
      <col style={{width: '15%'}} />
      <col style={{width: '15%'}} />
      <col style={{width: '15%'}} />
      <col style={{width: '15%'}} />
      <col style={{width: '40%'}} />
    </colgroup>
    <tbody>
      {builds.length !== 0 && builds.map(x => 
        <Build
          key={x.id}
          { ...x }
          onClick={() => {
            updateTab({
              id:'explicit',
              url: x.id,
              label: x.id.split(':').reverse()[0]
            })
          }}
        />)
      }
    </tbody>
  </HTMLTable>
  </>
))
