import React from 'react'
import { NonIdealState, AnchorButton, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

export default () => (
  <NonIdealState
    icon={IconNames.CLEAN} 
    title="Ups, No Source Code is visible to us."
    description={`
      It seems that you do not have any repository available in your GitHub account.`}
    action={
      <AnchorButton
        large
        intent={Intent.PRIMARY}
        icon={IconNames.GIT_NEW_BRANCH}
        href={"https://github.com"}
      >
        Visit GitHub
      </AnchorButton>}
  />
)