import React, { useState } from 'react'
import { useSecureCreate, useSecureRemove, WhileIO, Pending, Failure, Success, SUCCESS } from 'react-hook-oauth2'
import { Spinner, EditableText, Button, Intent, FormGroup } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { IssueIcon } from 'components/core/Issue'

const Busy = WhileIO(
  Pending(() => <Spinner size={15} />),
  Failure(IssueIcon),
  Success(() => <></>),
)

const EdKey = ({ value, onCommit }) => {
  const [valid, updateValid] = useState(true)

  return (
    <FormGroup
      helperText="please use, only: a-zA-Z0-9_.-@"
      intent={valid ? Intent.NONE : Intent.DANGER}
    >
      <EditableText
        placeholder="Click to Edit Variable"
        defaultValue={value}
        intent={valid ? Intent.NONE : Intent.DANGER}
        onConfirm={(val) => {
          if (/^[a-zA-Z0-9_.-@]+$/.test(val)) {
            updateValid(true)
            onCommit(val)
          } else {
            updateValid(false)
          } 
        }}
      />
    </FormGroup>
  )
}

const EnvKey = ({ editable, value, onCommit }) => (
  editable
    ? <EdKey value={value} onCommit={onCommit} />
    : <span>{value}</span>
)

const EnvVal = ({ editable, value, onCommit }) => (
  editable
    ? <FormGroup>
        <EditableText
          placeholder="Click to Edit Value"
          defaultValue={value}
          onConfirm={onCommit}
        />
      </FormGroup>
    : <span>{value}</span>
)

const EnvDel = ({ onClick }) => (
  <Button
    small
    minimal
    intent={Intent.DANGER}
    icon={IconNames.REMOVE}
    onClick={onClick}
  />
)

const UI = ({ env }) => {
  const [{id, name, value, editable }, updateEnv] = useState(env)

  const HOST = process.env.REACT_APP_API
  const { status, commit } = useSecureCreate(`${HOST}/sourcecode/config/${encodeURIComponent(id)}`)
  const { status: statusRemove, sequence } = useSecureRemove()

  const updateKey = (x) => {
    updateEnv({ name: x, id, value, editable })
    commit({ id: `${id.replace(']', `/${x}]`)}`, value })
  }
  const updateVal = (x) => {
    updateEnv({ id, name, value: x, editable })
    commit({ id: `${id.replace(']', `/${name}]`)}`, value: x })
  }

  if (statusRemove instanceof SUCCESS)
    return null

  return (
    <tr>
      <td><Busy status={status} /></td>
      <td><EnvKey editable={editable} value={name} onCommit={updateKey} /></td>
      <td><EnvVal editable={editable} value={value} onCommit={updateVal} /></td>
      <td><EnvDel onClick={() => sequence(`${HOST}/sourcecode/config/${encodeURIComponent(id.replace(']', `/${name}]`))}`) }/></td>
    </tr>
  )
}

export default UI

