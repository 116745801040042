import React from 'react'
import { useSecureLookup, WhileIO, Success } from 'react-hook-oauth2'
import { Spec } from 'components/Build'

const HOST = process.env.REACT_APP_API

const UI = WhileIO(
  Success(({ content }) => (<Spec {...content} />)),
)

export default ({ id }) => {
  const { status } = useSecureLookup(`${HOST}/sourcecode/${encodeURIComponent(id)}`)

  return (<UI status={status} />)
}
