import React from 'react'
import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

const Ikon = ({ value }) => {
  switch (value) {
    case 'pending':
      return <Icon icon={IconNames.UPDATED} />
    case 'success':
      return <Icon icon={IconNames.TICK_CIRCLE} />
    case 'failure':
      return <Icon icon={IconNames.ERROR} />
    default:
      return <Icon icon={IconNames.INFO_SIGN} />
  }
}


export default ({ value, children }) => (
  <span className={`chrn-text-${value}`}>
    <Ikon value={value} />
    {!children ? null : children}
  </span>
)