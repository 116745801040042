import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { useOAuth2, WhileIO, Failure, Success } from 'react-hook-oauth2'
import { Lobby } from 'components/Lobby'
import { UrlSourceCode } from 'crates/SourceCode'
import { Layout } from 'components/Layout'
import { Activity } from 'components/Activity'
import { ThemeProvider } from 'emotion-theming'
import { Config as ConfigSourceCode } from 'crates/SourceCode'
import { Config as ConfigAccount } from 'crates/Account'

/*
import CookieConsent from 'react-cookie-consent'

const Cookie = () => (
  <CookieConsent
    location="bottom"
    buttonText="Accept!"
    cookieName="analytics.accepted"
    style={{ background: "#394B59" }}
    buttonStyle={{ color: "white", background: "#DB3737", fontSize: "13px" }}
    expires={150}
  >
    The https://assay.it uses cookies and sends usage data to developers to enhance the
    user experience. Please read Terms of Service about the procedures of data collection
    and rights to refuse them.
  </CookieConsent>
)
*/


const UI = () => {
  return (
    <>
    <Router hashType="noslash">
      <Layout>
        <Switch>
          <Route exact path="/" component={Activity} />
          <Route exact path="/config/sourcecode" component={ConfigSourceCode} />
          <Route exact path="/config/profile" component={ConfigAccount} />
          <Route exact path="/:hub/:owner/:label" component={UrlSourceCode} />
          <Route exact path="/:hub/:owner/:label/:build" component={UrlSourceCode} />
        </Switch>
      </Layout>
    </Router>
    </>
  )
}

const IO = WhileIO(
  Failure(Lobby),
  Success(UI),
)

// https://github.com/system-ui/theme-specification
const theme = {
  breakpoints: ['480px', '768px', '1024px'],
  // space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
}

export default () => {
  const status = useOAuth2()

  return (
    <>
      <ThemeProvider theme={theme}>
        <IO status={status} />
      </ThemeProvider>
    </>
  )
}
