import React from 'react'
import { Tabs, Tab } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import * as faIconBrands from '@fortawesome/free-brands-svg-icons'
import * as faIconNames from '@fortawesome/free-solid-svg-icons'
import { TabName } from 'components/core'
import Header from './Head'
import AccessKey from './AccessKey'
import Team from './Team'
import Subscription from './Subscription'
import GitHub from './GitHub'
import './Account.css'

export default () => (
  <>
    <Header />
    <Tabs
      className="settings"
      renderActiveTabPanelOnly
    >
      <Tab id="secrets" panel={<AccessKey />}>
        <TabName fa icon={faIconNames.faKey}>Access Keys</TabName>
      </Tab>
      <Tab id="teams" panel={<Team />}>
        <TabName fa icon={faIconNames.faUsers}>Teams</TabName>
      </Tab>
      <Tab id="github" panel={<GitHub />}>
        <TabName fa icon={faIconBrands.faGithub}>GitHub</TabName>
      </Tab>
      <Tabs.Expander />
      <Tab id="subscription" panel={<Subscription />}>
        <TabName icon={IconNames.PROPERTY}>Upgrade</TabName>
      </Tab>

    </Tabs>
  </>
)