import React from 'react'
import { WhileIO, useSecureLookup, Pending, Failure, Success } from 'react-hook-oauth2'
import { None, SourceCode } from 'components/SuiteSourceCode'
import { Issue } from 'components/core/Issue'

const HOST = process.env.REACT_APP_API

const IO = WhileIO(Pending(None), Failure(Issue), Success(SourceCode))

export default ({ path }) => {
  const { status } = useSecureLookup(`${HOST}${path}`)
  const filename = path.split('/').reverse()[0]

  return (<IO status={status} filename={filename} lang="golang" />)
}
