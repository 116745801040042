import React from 'react'

export default () => (
  <ul>
    <li className="bp3-skeleton">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas consequat magna massa.
    </li>
    <li className="bp3-skeleton">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    </li>
    <li className="bp3-skeleton">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas consequat magna massa.
    </li>
    <li className="bp3-skeleton">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    </li>
    <li className="bp3-skeleton">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas consequat magna massa.
    </li>
    <li className="bp3-skeleton">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    </li>
  </ul>
)
