import React from 'react'
import { useSecureCreate, PENDING, SUCCESS } from 'react-hook-oauth2'
import { Button, Tooltip, Popover, Position, Intent, Menu, MenuItem, MenuDivider, Icon } from "@blueprintjs/core"
import { IconNames } from '@blueprintjs/icons'
import { withRouter } from 'react-router-dom'

const HOST = process.env.REACT_APP_API

const ReBuild = ({ sourcecode, endpoint, build, sequence, commit }) => (
  <Menu>
    <li className="bp3-menu-header">
      <h6 className="bp3-heading">
        <Icon icon={IconNames.REFRESH}/>&nbsp;&nbsp;Run Assay for
      </h6>
    </li>
    <MenuDivider />
    <MenuItem
      icon={IconNames.GIT_BRANCH}
      text="latest"
      onClick={() => {
        sequence(`${HOST}/webhook/sourcecode`)
        commit({
          id: sourcecode,
          endpoint,
        })
      }}/>
    <MenuItem
      icon={IconNames.GIT_COMMIT}
      text="commit"
      onClick={() => {
        sequence(`${HOST}/webhook`)
        commit({
          request: { number: build.pr, title: build.title },
          head: {id: build.head},
          base: {id: build.base},
          endpoint,
        })
      }}/>
  </Menu>
)

export default withRouter(({ history, build, sourcecode, config }) => {
  const endpoint = config && config.config && config.config.BUILD_ENDPOINT

  const { status, commit, sequence } = useSecureCreate()
  if (status instanceof SUCCESS) {
    return (
      <Tooltip content="Open details">
        <Button
          minimal
          intent={Intent.PRIMARY}
          icon={IconNames.DOCUMENT_OPEN}
          onClick={() => history.push(`/${status.content.build.split(':').join('/')}`)}
        />
      </Tooltip>
    )
  }

  return (
    <Popover content={<ReBuild sourcecode={sourcecode} build={build} endpoint={endpoint} commit={commit} sequence={sequence} />} position={Position.RIGHT_BOTTOM}>
      <Button
        minimal
        intent={Intent.PRIMARY}
        icon={IconNames.REFRESH}
        loading={status instanceof PENDING}
      />        
    </Popover>
  )
})
