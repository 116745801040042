import React from 'react'
import { Tabs, Tab, Icon, Breadcrumbs } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { SuiteSourceCode } from 'crates/SuiteSourceCode'
import { Units } from 'components/Unit'
import { TabName } from 'components/core'
import './Suites.css'

export default ({ suite, build, onClick }) => {
  const { units, ...props } = suite

  const id = encodeURIComponent(suite.id)

  return (
    <Tabs className="suites" renderActiveTabPanelOnly>
      <Breadcrumbs
        className="bp3-text-small"
        items={[
          { text: <TabName icon={IconNames.DIAGRAM_TREE}>Suites</TabName>, onClick },
          { text: <>{props.suite}&nbsp;<Icon icon={IconNames.DOUBLE_CHEVRON_RIGHT} /></> },
        ]}
      />
      <Tab
        id="suite"
        panel={<Units units={units} />}
      >
        <TabName icon={IconNames.FORM}>Summary</TabName>
      </Tab>
      <Tab
        id="source"
        panel={<SuiteSourceCode path={`/sourcecode/${id}`} />}
      >
        <TabName icon={IconNames.CODE}>View Source</TabName>
      </Tab>
    </Tabs>
  )
}
