import React from 'react'
import { HTMLTable } from '@blueprintjs/core'
import Unit from './Unit'

export default ({ units }) => (
  <HTMLTable interactive>
    <colgroup>
      <col style={{ width: '25%' }} />
      <col style={{ width: '5%' }} />
      <col style={{ width: '20%' }} />
      <col style={{ width: '50%' }} />
    </colgroup>
    <tbody>
      {units && units.map(x => <Unit key={x.id} { ...x }/>)}
    </tbody>
  </HTMLTable>
)
