import React from 'react'
import Nav from './Nav'
import Hero from './Hero'
import Footer from 'components/Layout/Footer'

export default () => (
  <>
    <Nav />
    <Hero />
    <Footer />
  </>
)