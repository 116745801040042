import React from 'react'
import { NonIdealState, Card } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

export default () => (
  <Card elevation={2}>
    <NonIdealState
      icon={IconNames.RING} 
      title="Nothing is found!"
      description={``}
    />
  </Card>
)

