import React from 'react'
import { NonIdealState, Button, Intent, Card } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { useSecureCreate, PENDING, SUCCESS } from 'react-hook-oauth2'
import { withRouter } from 'react-router-dom'

const HOST = process.env.REACT_APP_API

const ReBuild = (history, sourcecode) => {
  const { status, commit } = useSecureCreate(`${HOST}/webhook/sourcecode`)

  if (status instanceof SUCCESS) {
    return (
      <Button
        large
        intent={Intent.PRIMARY}
        icon={IconNames.DOCUMENT_OPEN}
        onClick={() => history.push(`/${status.content.build.split(':').join('/')}`)}
      >
        View Assay!
      </Button>
    )
  }

  return (
    <Button
      large
      intent={Intent.PRIMARY}
      icon={IconNames.PLAY}
      loading={status instanceof PENDING}
      onClick={() => commit({ id: sourcecode })}
    >
      Assay It
    </Button>
  )
}

export default withRouter(({ history, sourcecode }) => (
  <Card elevation={2}>
    <NonIdealState
      icon={IconNames.LAB_TEST} 
      title="No assessments is done, yet!"
      description={`
        The repository is properly configured and integrated with Assay It.
        You have not triggered any assessments for this repository.
      `}
      action={ReBuild(history, sourcecode)}
    />
  </Card>
))

