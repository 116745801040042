import React, { useState, useEffect } from 'react'
import {
  WhileIO,
  Pending,
  Failure,
  Success,
  PENDING,
  FAILURE,
  useSecureIO,
  secureUpdate,
  secureRemove,
} from 'react-hook-oauth2'
import { Icon, Colors, Switch, Spinner, Tooltip, Position, Classes, Intent, Tag } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { Issue, NoHubSourceCode } from 'components/core/Issue'
import { IssueIcon } from 'components/core/Issue'
import { Surface, Schema, None } from 'components/Surface'
import { WhoIs } from 'react-hook-oauth2/lib/hoc'
import { profile } from 'toolkit/account'

const API = process.env.REACT_APP_API

const SourceCode = ({ json }) => (
  <div style={{fontSize: '18px'}}>
    <Icon
      icon={json.private ? IconNames.SHIELD : IconNames.GIT_REPO}
      iconSize={20}
      style={{color: Colors.GRAY1}}
    />&nbsp;{json.title}
  </div>
)

const remove = ({ id }) => secureRemove(`${API}/account/sourcecode`, { id })
const update = ({ id }) => secureUpdate(`${API}/account/sourcecode`, { id }) 

const Config = ({ json: { id, enabled } }) => {
  const [ state, updateState ] = useState(enabled)
  const rm = useSecureIO(remove)
  const up = useSecureIO(update)
  const pending = rm.status instanceof PENDING || up.status instanceof PENDING
  const failure = rm.status instanceof FAILURE || up.status instanceof FAILURE

  useEffect(() => {
    rm.status.onSuccess(_ => updateState(false))
  }, [rm.status])

  useEffect(() => {
    up.status.onSuccess(_ => updateState(true))
  }, [up.status])

  return (
    <>
      <Switch
        checked={state}
        innerLabelChecked="on" innerLabel="off"
        onChange={() => !state ? up.commit({ id }) : rm.commit({ id })}
      >
        &nbsp;
        {pending && <Spinner size='16'/>}
        {failure && <IssueIcon status={rm.status instanceof FAILURE ? rm.status : up.status} />}
      </Switch>
    </>
  )
}

const Limits = ({ active }) => {
  if (active === 'Basic')
    return (
      <>
        Only <b>public</b> repositories are accessible.
        <b>Upgrade</b> your profile with the access to <b>private</b> repositories.
      </>
    )
  
  if (active === 'Personal')
    return (
      <>
        Only <b>your</b> private and public repositories are accessible.
        <b>Upgrade</b> your profile the access to repositories owned by
        your <b>teams</b> and organizations.
      </>
    )

  if (active === 'Team')
    return (
      <>
        Check team accessibility settings at <b>your profile</b>.
      </>
    )

  return null
}

const Important = () => WhoIs(({ scopes }) => {
  const active = profile(scopes)

//
  return (
    <Tooltip
      className={Classes.TOOLTIP_INDICATOR}
      position={Position.RIGHT}
      intent={Intent.WARNING}
      content={
        <div style={{width: '20em'}}>
          Your current profile is <Tag intent={Intent.SUCCESS}>{active}</Tag>.
          &nbsp;
          <Limits active={active} />
        </div>}
    >
      Don't see your repo?
    </Tooltip>
  )
})

const List = ({ content }) => (
  <>
    <div className="bp3-running-text bp3-text-large">
      <Important />
    </div>
    <Surface content={content} interactive>
      <Schema width='80%' title='Name' Component={SourceCode} />
      <Schema width='20%' Component={Config} />
    </Surface>
  </>
)

export default WhileIO(
  Pending(None),
  Failure(Issue),
  Success(List, (content) => content.length !== 0),
  Success(NoHubSourceCode, (content) => content.length === 0),
)
