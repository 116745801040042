import React, { useState } from 'react'
import { Ikons } from 'components/core/Status'
import { SourceCodeJSON, SourceCodeText } from 'components/SuiteSourceCode'
import { Icon, Navbar, Collapse } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import moment from 'moment'

const Status = ({ status, id }) => (
  <td className="bp3-text-overflow-ellipsis">
    <Ikons value={status}>&nbsp;{id}</Ikons>
  </td>
)

const Duration = ({ duration }) => (
  <td className="bp3-text-overflow-ellipsis">
    <Icon className="bp3-text-secondary" icon={IconNames.TIME} />
    &nbsp;&nbsp;
    {moment.duration(duration).asSeconds()} sec
  </td>
)

const Summary = ({ status, reason, payload }) => (
  <td className="bp3-text-overflow-ellipsis" style={{width: '25%'}}>
    <Icon className="bp3-text-secondary" icon={IconNames.CIRCLE_ARROW_DOWN} />
    &nbsp;Output:&nbsp;
    {status === "failure" ? reason : JSON.stringify(payload)}
  </td>
)

const Output = ({ visibility, status, reason, payload }) => (
  <td colSpan="6">
    <Collapse isOpen={visibility} keepChildrenMounted={false}>
      <div style={{padding: '7px'}}>
        {status === "failure" &&
          <SourceCodeText filename="Output" text={reason} />
        }
        {status !== "failure" &&
          <SourceCodeJSON filename="Output" json={payload || "None."} />
        }
      </div>
    </Collapse>
  </td>
)

export default (props) => {
  const [show, updateShow] = useState(false)

  return (
    <>
      <tr onClick={() => updateShow(!show)}>
        <Status {...props} />
        <td><Navbar.Divider /></td>
        <Duration {...props} />
        <Summary {...props} />
      </tr>
      <tr className="bp3-tr-collapse">
        <Output visibility={show} {...props} />  
      </tr>
    </>
  )
}
