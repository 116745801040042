import React, { useState } from 'react'
import { Card } from 'components/Build'
import { Suites, Suite } from 'components/Suite'
import { Box } from 'reflexbox'

export default (props) => {
  const [suite, updateSuite] = useState()

  return (
    <>
      <Card {...props} />
      <Box mt={'1em'}>
        {suite
          ? <Suite suite={suite} {...props} onClick={() => updateSuite(undefined)} />
          : <Suites onSuite={updateSuite} {...props} />}
      </Box>
    </>
  )
}


