import React from 'react'
import { Flex, Box } from 'reflexbox'
import { H2 as Hb2, H4 as Hb4, Icon } from '@blueprintjs/core'
import Avatar from 'react-avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const styleH2 = {
  height: '110px',
  margin: 0,
  alignItems: 'center',
  justifyContent: 'space-between',
}

export const H2 = ({ title, avatar, icon, children }) => (
  <Flex style={styleH2} >
    <Hb2>
      {avatar &&
        <Box mr={'15px'}>
          <Avatar src={avatar} size='60px' round='30px' />
        </Box>
      }
      {icon &&
        <Box mr={'15px'}>
          <Icon icon={icon} iconSize='48px' style={{color: '#394B59'}} />
        </Box>
      }
      {title}
    </Hb2>
    {children}
  </Flex>
)

const styleH4 = {
  margin: 0,
  alignItems: 'center',
  justifyContent: 'space-between',
}

export const H4 = ({ title, children }) => (
  <Flex style={styleH4} >
    <Hb4>{title}</Hb4>
    {children}
  </Flex>
)

export const TabName = ({ fa, icon, children }) => (
  <>
    {fa ? <FontAwesomeIcon color='#BFCCD6' icon={icon} /> : <Icon color='#BFCCD6' icon={icon}/>}
    &nbsp;
    <strong style={{color: '#106ba3'}}>
      {children}
    </strong>
  </>
)