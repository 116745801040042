import React from 'react'
import { Divider, Code } from '@blueprintjs/core'
import { profile } from 'toolkit/account'

const Grant = ({id, title, children}) => (
  <>
    <p><Code>{id}</Code>&nbsp;<strong>{title}</strong></p>
    <p>{children}</p>
    <Divider/>
  </>
)

const Public = () => (
  <Grant
    id="(no scope)"
    title="Read Public Profile"
  >
    Read-only access to public profile and public repositories.
  </Grant>
)

const CommitStatus = () => (
  <Grant
    id="repo:status"
    title="Update Commit Status"
  >
    Access to commit status on repositories connected with the service.
  </Grant>
)

const Repo = () => (
  <Grant
    id="repo"
    title="Private and Public Repositories"
  >
    Access to source code of public and private repositories.
  </Grant>
)

const Org = () => (
  <Grant
    id="read:org"
    title="Organization membership"
  >
    Read-only access to organization membership.
  </Grant>
)

export default ({ scopes }) => {

  switch (profile(scopes)) {
  case 'Basic':
    return (
      <>
        <Public />
        <CommitStatus />
      </>
    )
  case 'Personal':
    return (
      <>
        <Public />
        <Repo />
      </>
    )
  case 'Team':
    return (
      <>
        <Public />
        <Repo />
        <Org />
      </>
    )
  default:
    return null
  }
}