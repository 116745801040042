import React, { useContext } from 'react'
import { Button, Intent, Callout, H4 } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { NewAccessKey } from 'crates/NewAccessKey'
import { useSecureRemove, WhileIO, Success, Failure, Pending, PENDING, SUCCESS } from 'react-hook-oauth2'
import { Surface, Schema, None, } from 'components/Surface'
import { Issue } from 'components/core/Issue'
import { Content, Provider } from 'structs/AccessKey'

const HOST = process.env.REACT_APP_API

const Empty = () => (
  <Callout
    intent={Intent.PRIMARY}
    title="You have no personal access keys."
  >
    Create New access key to enable integration with other applications.
  </Callout>
)

const Remove = ({ json }) => {
  const id = json.id.split(/^\[|\]$/)[1].split('/').reverse()[0]
  const { status, sequence } = useSecureRemove()

  return (
    <Button
      small
      minimal
      disabled={status instanceof SUCCESS}
      loading={status instanceof PENDING}
      intent={Intent.DANGER}
      icon={IconNames.REMOVE}
      onClick={() => sequence(`${HOST}/account/access/${id}`)}
    />
  )
}

const SurfaceUI = () => {
  const { secrets } = useContext(Content)

  if (secrets.length === 0)
    return (<Empty />)

  return (
    <Surface content={secrets}>
      <Schema
        width='10%'
        Component={Remove}
      />
      <Schema
        width='90%'
        title="Key"
        format={(json) => json.id.split(/^\[|\]$/)[1].split('/').reverse()[0]}
      />
    </Surface>
  )
}

const SurfaceIO = WhileIO(
  Pending(None),
  Failure(Issue),
  Success(SurfaceUI),
)

const AccessKeys = () => {
  const { status, append } = useContext(Content)

  return (
    <div className="bp3-running-text">
      <H4>My Personal Access Keys</H4>
      <p>
        Access Keys can be used for CI/CD integrations or access Assay It REST API.
      </p>
      <NewAccessKey append={append}/>
      <SurfaceIO status={status} />
    </div>
  )
}

export default () => (
  <Provider>
    <AccessKeys />
  </Provider>
)
