import React from 'react'
import { HTMLTable, Icon, Tabs, Tab, Navbar, NonIdealState, Code, AnchorButton, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { Ikons as Status } from 'components/core/Status'
import { SourceCodeJSON } from 'components/SuiteSourceCode' 
import { TabName } from 'components/core'
import './Suites.css'

const rate = (passed, failed) => (
   (100 * passed / (failed + passed)).toFixed(2)
)

const Suite = ({ suite, status, passed, failed, duration, units, onClick }) => (
  <tr className={`chrn-interactive-${status}`} onClick={onClick}>
    <td><Status value={status}>&nbsp;{suite}</Status></td>
    <td><Navbar.Divider /></td>
    <td>Cases: {units ? units.length : '-'}</td>
    <td>Success:&nbsp;{passed ? rate(passed, failed) : '-'} %</td>
    <td>
      <Icon className="bp3-icon-secondary" icon={IconNames.TIME} />
      &nbsp;&nbsp;{duration ? moment.duration(duration).asSeconds() : '-'}&nbsp;sec
    </td>
  </tr>
)

const SuitesEmpty = () => (
  <NonIdealState 
    icon={IconNames.COG} 
    title="Invalid configuration!"
    description={<p className="bp3-text-large">Unable to run api checks! Either <Code>.assay.json</Code> is missing or misconfigured.</p>}
    action={<AnchorButton icon={IconNames.CODE} large intent={Intent.PRIMARY}>Fix .assay.json</AnchorButton>}
  />
)

const Suites = ({ rootUrl, build, suites, history, onSuite }) => (
  <>
    {(!suites || suites.length === 0) ? <SuitesEmpty /> :
      <HTMLTable interactive>
        <colgroup>
          <col style={{ width: '25%' }}/>
          <col style={{ width: '5%' }}/>
          <col style={{ width: '20%' }}/>
          <col style={{ width: '30%' }}/>
          <col style={{ width: '20%' }}/>
        </colgroup>
        <tbody>
          {/* TODO: handling of empty suites */}
          
          {suites && suites.map(x =>
            <Suite 
              key={x.id}
              build={build}
              { ...x }
              onClick={() => onSuite(x)}
            />
          )}
        </tbody>
      </HTMLTable>
    }
  </>
)

export default withRouter((props) => (
  <Tabs className="suites" renderActiveTabPanelOnly>
    <Tab id="suites" panel={<Suites { ...props } />}>
      <TabName icon={IconNames.DIAGRAM_TREE}>Suites</TabName>
    </Tab>
    <Tab id="config" panel={<SourceCodeJSON filename=".assay.json" json={props.config} />}>
      <TabName icon={IconNames.PROPERTIES}>View Config</TabName>
    </Tab>
  </Tabs>
))

