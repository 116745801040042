import React from 'react'
import { NonIdealState, AnchorButton, Intent, Card } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

export default () => (
  <Card elevation={2}>
    <NonIdealState
      icon={IconNames.CODE} 
      title="Not yet configured!"
      description={`
        The repository is not yet configured. You have to declare interface 
        assessments and assay json specification.
      `}
      action={
        <AnchorButton
          large
          intent={Intent.PRIMARY}
          icon={IconNames.LEARNING}
          href={"https://github.com/assay-it/example.assay.it"}
        >
          Learn More
        </AnchorButton>
      }
    />
  </Card>
)

