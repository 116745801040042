import React from 'react'
import { Flex, Box } from 'reflexbox'
import { H5 } from '@blueprintjs/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlack, faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons'
import './Footer.css'
import logo from '../icon.v3.svg'

export default () => (
  <footer>
    <Flex>
      <Box
        width={[3/12]}
        ml={'5em'}
        mt={'2em'}
        style={{height: '30vh'}}
      >
        <ul>
          <li><H5 className="logo"><img alt="assay.it" src={logo} style={{width: 19, height: 19}} />&nbsp;assay.it</H5></li>
          <li>© 2019 assay.it</li>
        </ul>
      </Box>
      <Box
        width={[3/12]}
        mt={'2em'}
      >
        <ul>
          <li><H5>Support</H5></li>
          <li>
            <a href="https://assay.it/doc">
              Documentation
            </a>
          </li>
          <li>
            <a target="_blank" href="https://assay-it.slack.com" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faSlack} />&nbsp;Slack
            </a>
          </li>
        </ul>
      </Box>
      <Box
        width={[3/12]}
        mt={'2em'}
      >
        <ul>
          <li><H5>Social</H5></li>
          <li>
            <a href="https://twitter.com/ItAssay" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} />&nbsp;Twitter
            </a>
          </li>
          <li>
            <a href="https://github.com/assay-it" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} />&nbsp;GitHub
            </a>
          </li>
        </ul>
      </Box>
      <Box
        width={[3/12]}
        mt={'2em'}
      >
        <ul>
          <li><H5>Company</H5></li>
          <li><a href="https://assay.it/tos">Terms</a></li>
        </ul>
      </Box>
    </Flex>
  </footer>
)