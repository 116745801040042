import React from 'react'
import { Icon, Tooltip, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

const label = ({ type, title }) => {
  switch(type) {
    case 'https:/httpstatuses.com/401':
      return 'Your session is expired!'

    case 'https:/httpstatuses.com/403':
      return (
        <>
          <div>Ops, Sorry!</div>
          <div>You do not have rights to perform this operation.</div>
          <div>Please try to sign-in again!</div>
        </>
      )

    default:
      return `Ops, Sorry, Server fails with ${title}`
  }
}

//
export default ({ status: { reason } }) => (
  <Tooltip
    intent={Intent.DANGER}
    content={label(reason)}
  >
    <Icon icon={IconNames.WARNING_SIGN} iconSize={15} style={{color: '#DB3737'}}/>
  </Tooltip>
)
  