import React from 'react'
import { signup } from 'toolkit/account'
import {
  Navbar,
  Alignment,
  H2,
  Button,
} from '@blueprintjs/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as icons from '@fortawesome/free-brands-svg-icons'
import logo from 'components/Layout/icon.v3.light.svg'

export default () => (
  <Navbar className="bp3-dark" fixedToTop={true}>
    <Navbar.Group align={Alignment.LEFT}>
      <H2><img alt="assay.it" src={logo} style={{width: 48, height: 48}} /></H2>
    </Navbar.Group>
    <Navbar.Group align={Alignment.RIGHT}>
      <Button
        minimal
        large
        outlined
        onClick={signup}
      >
        <FontAwesomeIcon icon={icons.faGithub} />&nbsp;Sign In
      </Button>
    </Navbar.Group>
  </Navbar>
)
