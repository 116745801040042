import React from 'react'
import { H2 } from 'components/core'
import { Tag, Intent } from '@blueprintjs/core'
import { WhoIs } from 'react-hook-oauth2'
import { profile } from 'toolkit/account'

export default () => WhoIs(
  ({ avatar, scopes }) => (
    <H2 avatar={avatar} title={"Profile"}>
      {scopes && 
        <div>
          Active Plan:&nbsp;
          <Tag intent={Intent.SUCCESS}>{profile(scopes)}</Tag>
        </div>
      }
    </H2>
  )
)
