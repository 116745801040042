import React from 'react'

export default ({ value }) => {
   switch (value) {
      case 'pending':
         return <>Pending</>
      case 'success':
         return <>Passed</>
      case 'failure':
         return <>Failed</>
      default:
         return <></>
   }
}
