import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { Card, H6, Icon, Divider } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import * as style from 'react-syntax-highlighter/dist/esm/styles/hljs'

export const None = () => (
  <Card>
  <ul>
    <li className="bp3-skeleton">Lorem ipsum dolor sit amet</li>
    <li className="bp3-skeleton">Lorem ipsum dolor sit amet</li>
    <li className="bp3-skeleton">Lorem ipsum dolor sit amet</li>
    <li className="bp3-skeleton">Lorem ipsum dolor sit amet</li>
    <li className="bp3-skeleton">Lorem ipsum dolor sit amet</li>
    <li className="bp3-skeleton">Lorem ipsum dolor sit amet</li>
  </ul>
  </Card>
)

export const SourceCode = ({ lang, filename, content }) => (
  <Card>
    <H6><Icon icon={IconNames.DOCUMENT} />&nbsp;{decodeURIComponent(filename)}</H6>
    <Divider />
    <SyntaxHighlighter
      language={lang === "golang" ? "go" : lang}
      style={style.monoBlue}
      showLineNumbers
    >
      {content.replace(/\t/g, '  ')}
    </SyntaxHighlighter>
  </Card>
)

export const SourceCodeJSON = ({ filename, json }) => (
  <SourceCode lang="json" filename={filename} content={JSON.stringify(json, null, 2)} />
)

export const SourceCodeText = ({ filename, text }) => (
  <SourceCode lang="json" filename={filename} content={text} />
)
