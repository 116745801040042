import React from 'react'
import { Card, Icon, H4, Tooltip } from '@blueprintjs/core'
import { Chips } from 'components/core/Status'
import { IconNames } from '@blueprintjs/icons'
import Avatar from 'react-avatar'
import { Flex, Box } from 'reflexbox'
import { ReBuild } from 'crates/Actions'
import moment from 'moment'
import Progress from './Progress'

const Author = ({ name, avatar, homepage }) => (
  <li>
    <Avatar src={avatar} size={20} round="10px"/>&nbsp;
    <Tooltip content={`View repositories of ${name}`}>
       <a href={homepage} target="_blank" rel="noopener noreferrer">{name}</a>
    </Tooltip>
  </li>
)

const Commit = ({ commit, commit_url }) => (
  <li>
    <Icon icon={IconNames.GIT_COMMIT} />&nbsp;Commit:&nbsp;
    <a href={commit_url} target="_blank" rel="noopener noreferrer">{commit}</a>
  </li>
)

const Branch = ({ branch, branch_url }) => (
  <li>
    <Icon icon={IconNames.GIT_BRANCH} />&nbsp;Branch:&nbsp;
    <a href={branch_url} target="_blank" rel="noopener noreferrer">{branch}</a>
  </li>
)

const Created = ({ created }) => (
  <li>
    <Icon icon={IconNames.CALENDAR} />&nbsp;Started:&nbsp;
    {moment(created).fromNow()}
  </li>
)

const Duration = ({ suites }) => {
  let t = 0
  suites.forEach(x => t = t + x.duration)
  
  return (
    <li>
      <Icon icon={IconNames.TIME} />&nbsp;Duration:&nbsp;
      {moment.duration(t).asSeconds()} sec
    </li>
  )
}

export default ({ developer, head, build, suites, sourcecode, config }) => (
  <>
    <H4>
      {build.title}&nbsp;
      <Icon
        style={{color: '#A7B6C2'}}
        icon={IconNames.DRAG_HANDLE_VERTICAL}
      />
      <Chips value='none'>{build.pr}</Chips>
    </H4>
    <br/>
    <Progress suites={suites} build={build} />
    <br />
    <Card>
      <Flex>
        <Box width={[1/2]}>
          <ul>
            <Author {...developer} />
            <Commit {...head} />
            <Branch {...head} />
          </ul>
        </Box>
        <Box width={[1/2]}>
          <ul>
            <Created {...build} />
            <Duration suites={suites} />
          </ul>
        </Box>
        <Box>
          <ReBuild build={build} sourcecode={sourcecode.id} config={config} />
        </Box>
      </Flex>
    </Card>
  </>
)
