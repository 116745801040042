import React from 'react'
import { Icon, H2 } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { Box } from 'reflexbox'
import Avatar from 'react-avatar'
import { Chips } from 'components/core/Status'

export default ({ build, owner, sourcecode, suites, config }) => (
  <H2 style={{height: '84px', margin: 0}}>
    <Box m={'15px'}><Avatar src={owner.avatar} size='60px' round='30px' /></Box>
    <Icon
      icon={sourcecode.private ? IconNames.SHIELD : IconNames.GIT_REPO}
      iconSize={30}
      style={{'verticalAlign': 'middle'}}
    />
    &nbsp;
    <a href={owner.homepage}>{owner.name}</a>
    &nbsp;/&nbsp;
    <a href={sourcecode.url}>{sourcecode.label}</a>
    &nbsp;&nbsp;&nbsp;
    {build && <Chips value={build.status} />}
  </H2>
)