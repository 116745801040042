import React from 'react'
import {
  Navbar,
  Alignment,
  H2,
  Menu,
  MenuItem,
  Popover,
  Position,
  Icon,
  Intent,
  MenuDivider,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { Link } from 'react-router-dom'
import { signout, WhoIs } from 'react-hook-oauth2'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as icons from '@fortawesome/free-brands-svg-icons'
import Avatar from 'react-avatar'
import logo from './icon.v3.svg'

const Settings = (username, history) => (
  <Menu>
    <li className="bp3-menu-header">
      <h6 className="bp3-heading">
        <FontAwesomeIcon icon={icons.faGithub} />&nbsp;&nbsp;{username && username.split(':').reverse()[0]}
      </h6>
    </li>
    <MenuDivider />
    <MenuItem
      icon={IconNames.CODE}
      text="Repositories"
      onClick={() => history.push('/config/sourcecode')}
    />
    <MenuItem
      icon={IconNames.COG}
      text="Profile"
      onClick={() => history.push('/config/profile')}
    />
    <MenuItem icon={IconNames.POWER} text="Sign Out" onClick={signout} intent={Intent.DANGER} />
  </Menu>  
)

const UI = withRouter(({ username, avatar, history }) => (
  <Navbar fixedToTop={true}>
    <Navbar.Group align={Alignment.LEFT}>
      <Link to='/'>
        <H2><img alt="assay.it" src={logo} style={{width: 48, height: 48}} /></H2>
      </Link>
    </Navbar.Group>

    {username &&
    <Navbar.Group align={Alignment.RIGHT}>
      <Popover content={Settings(username, history)} position={Position.BOTTOM}>
        <>
          <Avatar src={avatar} size='30px' round='15px' />
          <Icon icon={IconNames.CHEVRON_DOWN} iconSize={10} />
        </>
      </Popover>
    </Navbar.Group>
    }
  </Navbar>
))

export default () => WhoIs(UI)