import React from 'react'
import { signup } from 'toolkit/account'
import { Button, H1 } from '@blueprintjs/core'
import './Hero.css'

export default () => (
  <div className="hero">
    <section>
      <H1>Confirm Quality, Eliminate Risks in your Serverless applications.</H1>
      <p>
      Quality assurance of serverless applications is more complex than doing it for other runtimes. Engineering teams spend twice as much time maintaining testing environments and mocks of cloud dependencies instead of building a loyal relationship with their customers, assay.it has you covered.
      </p>

      <Button
        minimal
        large
        onClick={signup}
      >
        Sign In with GitHub
      </Button>
    </section>
    <section>
      <img src="https://assay.it/images/hologram.svg" alt="Confirm Quality, Eliminate Risks"/>
    </section>
  </div>
)