import React from 'react'
import { AnchorButton, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { H2 } from 'components/core'
import { PENDING } from 'react-hook-oauth2'

export default ({ status, action }) => (
  <H2 icon={IconNames.GIT_REPO} title="Add repositories">
    <AnchorButton
      minimal
      intent={Intent.DANGER}
      icon={IconNames.REFRESH}
      loading={status instanceof PENDING}
      onClick={() => action()}
    >
      Sync Now
    </AnchorButton>
  </H2>
)
