import React, { useState } from 'react'
import { useSecureLookup, useSecureIO, secureRemove, WhileIO, Pending, Failure, Success, SUCCESS, PENDING } from 'react-hook-oauth2'
import { HTMLTable, Button, Intent, H4, Card, Callout } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { Issue } from 'components/core/Issue'
import EnvKey from './EnvKey'

const API = process.env.REACT_APP_API

const EnvAboutIt = () => (
  <>
    <H4>Settings</H4>
    <br/>
    <p>
      Settings allows your to customize assessment process via environment variables.
      These variables are provided into your code during the evaluation process.   
      All variables values are encrypted and obfuscated on user interface.
    </p>
  </>
)

const EnvTable = ({ header, children }) => (
  <HTMLTable>
    <colgroup>
      <col style={{width: '10%'}} />
      <col style={{width: '40%'}} />
      <col style={{width: '40%'}} />
      <col style={{width: '10%'}} />
    </colgroup>
    <thead>
      {header}
    </thead>
    <tbody>
      {children}
    </tbody>
  </HTMLTable>
)

const EnvHead = ({ create }) => (
  <tr>
    <th></th>
    <th>Variable</th>
    <th>Value</th>
    <th>
      <Button
        small
        minimal
        icon={IconNames.ADD}
        intent={Intent.PRIMARY}
        onClick={create}
      >
        New
      </Button>
    </th>
  </tr>
)

const xx = ({ id }) => secureRemove(`${API}/account/sourcecode`, { id })

const Remove = ({ sourcecode }) => {
  const { status, commit } = useSecureIO(xx)

  return (
    <Button
      large
      minimal
      intent={Intent.DANGER}
      loading={status instanceof PENDING}
      disabled={status instanceof SUCCESS}
      onClick={() => commit({ id: sourcecode.id })}
    >
      Remove Integration
    </Button>
  )
}

const List = ({ content, sourcecode }) => {
  const [ list, updateList ] = useState(content)
  const create = () => updateList(
    list.concat([{ id: sourcecode.id.replace(']', '/undefined]'), editable: true }])
  )


  return (
    <>
      <Card>
      <EnvAboutIt />
      <EnvTable header={<EnvHead create={create} />}>
        {list.map(x => 
          <EnvKey
            key={x.id}
            env={{
              ...x,
              id: sourcecode.id,
              name: x.id.split(/^\[|\]$/)[1].split('/').reverse()[0] === "undefined" ? undefined : x.id.split(/^\[|\]$/)[1].split('/').reverse()[0],
            }}
          />
        )}
      </EnvTable>
      {list.length === 0 &&
        <Callout
          intent={Intent.PRIMARY}
          title="No Variables"
        >
          Add environment variables to customize behaviour of your assessment scripts.
        </Callout>
      }

      </Card>
      <Card style={{marginTop: '2em'}}>
      <Callout
        intent={Intent.DANGER}
        title="Danger Zone"
      >
        Following actions cannot be undone!
      </Callout>
      <br />
      <Remove sourcecode={sourcecode} />
      </Card>
    </>
  )
}

const None = () => (
  <ul>
    <li className="bp3-skeleton">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas consequat magna massa.
    </li>
    <li className="bp3-skeleton">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    </li>
    <li className="bp3-skeleton">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas consequat magna massa.
    </li>
    <li className="bp3-skeleton">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    </li>
  </ul>
)

const IO = WhileIO(
  Pending(None),
  Failure(Issue),
  Success(List),
)

export default ({ sourcecode }) => {
  const HOST = process.env.REACT_APP_API
  const { status } = useSecureLookup(`${HOST}/sourcecode/config/${encodeURIComponent(sourcecode.id)}`)

  return (<IO status={status} sourcecode={sourcecode} />)
}
