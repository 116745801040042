import React from 'react'
import Nav from './Nav'
import { Flex, Box } from 'reflexbox'
import Footer from './Footer'

export default ({ avatar, sidebar, header, children }) => (
  <>
    <Flex mb={'8em'}>
      <Nav />
      <Box
        width={['90vw']}
        mt={'50px'}
        ml={'3em'}
        mr={'3em'}
        style={{minHeight: '550px', margin: '50px auto', maxWidth: '80em'}}
      >
        {children}
      </Box>
    </Flex>
    <Footer />
  </>
)
