import React, { useState, useEffect } from 'react'
import { H4, Callout, Intent, Classes, Switch, Spinner } from '@blueprintjs/core'
import { WhoIs } from 'react-hook-oauth2/lib/hoc'
import { profile } from 'toolkit/account'
import { Surface, Schema, None, } from 'components/Surface'
import { WhileIO, Success, Failure, Pending, useSecureLookup, secureRemove, secureCreate, useSecureIO, PENDING, FAILURE } from 'react-hook-oauth2'
import { Issue, IssueIcon } from 'components/core/Issue'
import Avatar from 'react-avatar'

const API = process.env.REACT_APP_API

const Upgrade = ({ active }) => (
  <>
    {(active === 'Team' || active === '') ? null :
      <Callout
        intent={Intent.WARNING}
        title="Upgrade Subscription"
      >
        The feature is only available with <b>Team</b> subscription.
      </Callout>
    }
  </>
)

const Empty = () => (
  <Callout
    intent={Intent.WARNING}
    title="No teams."
  >
    You are not member of any organization in GitHub.
  </Callout>
)

const remove = ({ id }) => secureRemove(`${API}/account/team`, { id })
const create = ({ id }) => secureCreate(`${API}/account/team`, { id }) 

const Config = ({ json: { id, enabled } }) => {
  const [ state, updateState ] = useState(enabled)
  const rm = useSecureIO(remove)
  const up = useSecureIO(create)
  const pending = rm.status instanceof PENDING || up.status instanceof PENDING
  const failure = rm.status instanceof FAILURE || up.status instanceof FAILURE

  useEffect(() => {
    rm.status.onSuccess(_ => updateState(false))
  }, [rm.status])

  useEffect(() => {
    up.status.onSuccess(_ => updateState(true))
  }, [up.status])

  return (
    <>
      <Switch
        innerLabelChecked="on" innerLabel="off"
        onChange={() => !state ? up.commit({ id }) : rm.commit({ id })}
        checked={state}      
      >
        &nbsp;
        {pending && <Spinner size='16'/>}
        {failure && <IssueIcon status={rm.status instanceof FAILURE ? rm.status : up.status} />}
      </Switch>
    </>
  )
}

const SurfaceUI = ({ content }) => {
  console.log(content)

  if (content.length === 0)
    return (<Empty />)

  return (
    <Surface content={content}>
      <Schema
        width='20%'
        Component={({ json }) => <Avatar src={json.avatar} size='30px' round='15px' />}
      />
      <Schema
        width='60%'
        title="Team"
        format={(json) => json.name}
      />
      <Schema
        width='20%'
        Component={Config}
      />
    </Surface>
  )
}

const SurfaceIO = WhileIO(
  Pending(None),
  Failure(Issue),
  Success(SurfaceUI),
)

const Teams = () => {
  const { status } = useSecureLookup(`${API}/account/team`)

  return (<SurfaceIO status={status} />)
}

const UI = ({ scopes }) => {
  const active = profile(scopes)
  
  return (
    <div className={[Classes.RUNNING_TEXT, Classes.TEXT_LARGE].join(' ')}>
      <H4>My Teams</H4>
      <p>
        Expand visibility on GitHub repositories by filter on/off teams.
        These settings do not change your actual team membership at GitHub. 
      </p>
      <Upgrade active={active}/>
      {active === 'Team' && <Teams />}
    </div>
  )
}

export default () => WhoIs(UI)