import React, { useState } from 'react'
import { Tabs, Tab } from '@blueprintjs/core'
import { Spec } from 'components/Build'
import { History } from 'crates/History'
import { SourceCode } from 'crates/SourceCode'
import Settings from './Settings'
import { NoAssay, NoConfig, Pending } from 'components/core/Issue'
import { IconNames } from '@blueprintjs/icons'
import { TabName } from 'components/core'

const Latest = (props) => {
  if (!props.config || !props.config.suites || props.config.suites.length === 0) {
    return (<NoConfig user={props.user}/>)
  }

  if (!props.build) {
    return (<NoAssay sourcecode={props.sourcecode.id}/>)
  }

  if (!props.suites) {
    return (<Pending />)
  }

  return (<Spec {...props} />)
}

export default (props) => {
  const [tab, updateTab] = useState({id: 'latest'})

  return (
    <Tabs
      renderActiveTabPanelOnly
      selectedTabId={tab.id}
      onChange={(newTab) => updateTab({id: newTab})}
    >
      <Tab id="latest" panel={<Latest {...props} />}>
        <TabName icon={IconNames.CONTROL}>Latest</TabName>
      </Tab>
      <Tab id="history" panel={<History updateTab={updateTab} {...props} />}>
        <TabName icon={IconNames.HISTORY}>History</TabName>
      </Tab>
      {tab.url &&
        <Tab id="explicit" panel={<SourceCode id={tab.url} />}>
          <TabName icon={IconNames.BUILD}>{tab.label}</TabName>
        </Tab>
      }
      <Tabs.Expander />
      {props.user && props.user.type === "Owner" &&
        <Tab id="config" panel={<Settings sourcecode={props.sourcecode} />}>
          <TabName icon={IconNames.COG}>Settings</TabName>
        </Tab>
      }
    </Tabs>
  )
}