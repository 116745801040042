import React from 'react'
import { Tag, Intent } from '@blueprintjs/core'

const status = (value) => {
  switch (value) {
  case 'success':
    return Intent.SUCCESS
  case 'pending':
    return Intent.WARNING
  case 'failure':
    return Intent.DANGER
  default:
    return Intent.NONE  
  }
}

export default ({ value, children, ...props }) => (
  <>
    {!value ? null :
      <Tag minimal intent={status(value)} {...props}>{children || value}</Tag>
    }
  </>
)
