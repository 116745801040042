import React from 'react'
import { HTMLTable } from '@blueprintjs/core'
import Schema from './Schema'
import './Surface.css'

const TH = ({ Control, title }) => (
  Control
    ? <th>{Control}</th>
    : <th>{title}</th>
)

const Head = ({ children }) => (
  <thead>
    <tr>
      {React.Children.map(children, e => <TH { ...e.props } />)}
    </tr>
  </thead>
)

const Data = ({ children, json, onClick, onMouseOver, onMouseEnter, onMouseLeave, onMouseOut }) => (
  <tr
    onClick={onClick && (() => onClick(json))}
    onMouseOver={onMouseOver && (() => onMouseOver(json))}
    onMouseEnter={onMouseEnter && (() => onMouseEnter(json))}
    onMouseLeave={onMouseLeave && (() => onMouseLeave(json))}
    onMouseOut={onMouseOut && (() => onMouseOut(json))}
  >
    {React.Children.map(children, e => (
      <td><Schema { ...e.props } json={json} onClick={onClick} /></td>
    ))}
  </tr>
)

export default props => (
  <HTMLTable interactive={props.interactive || false}>
    <colgroup>
      {React.Children.map(props.children, e => <col style={{width: e.props.width}} />)}
    </colgroup>
    <Head {...props} />    
    <tbody>
      {props.content && props.content.map((json) => <Data key={json.id} json={json} {...props} />)}
    </tbody>
  </HTMLTable>
)