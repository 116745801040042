import React, { useState, useEffect } from 'react'
import { useSecureLookup } from 'react-hook-oauth2'
import { SourceCode } from 'components/SourceCode'
import { withRouter } from 'react-router-dom'

const HOST = process.env.REACT_APP_API

export default withRouter(
  ({ match }) => {
    const { hub, owner, label, build } = match.params
    const id = build
      ? encodeURIComponent(`[${hub}:${owner}/${label}/${build}]`)
      : encodeURIComponent(`[${hub}:${owner}/${label}]`)

    const [url, updateUrl] = useState(id)  
    const { status, retry, sequence } = useSecureLookup(`${HOST}/sourcecode/${id}`)
    if (url !== id) {
      sequence(`${HOST}/sourcecode/${id}`)
      updateUrl(id)
    }

    useEffect(() => {
      const ref = setInterval(
        () => {
          status.onSuccess(({ build }) => {
            if (build && build.status === 'pending') {
              retry()
            } else {
              clearInterval(ref)
            }
          })
        },
        10000,
      )
      return () => clearInterval(ref)
    }, [status, retry])

    return (<SourceCode status={status} />)
  }
)