import React from 'react'
import { HTMLTable } from '@blueprintjs/core'

const NoneItem = () => (
  <tr>
    <td><span className="bp3-skeleton">Lorem ipsum</span></td>
    <td><span className="bp3-skeleton">Lorem ipsum dolor sit amet</span></td>
    <td><span className="bp3-skeleton">Lorem ipsum dolor</span></td>
    <td><span className="bp3-skeleton">Lorem ipsum dolor</span></td>
  </tr>
)

export default () => (
  <HTMLTable>
    <tbody>
      <NoneItem />
      <NoneItem />
      <NoneItem />
    </tbody>
  </HTMLTable>
)
