import React, { useState, useEffect } from 'react'
import { useSecureLookup } from 'react-hook-oauth2'

const HOST = process.env.REACT_APP_API

//
export const Content = React.createContext()

//
export const Provider = ({ children }) => {
  const { status } = useSecureLookup(`${HOST}/account/access`)
  const [ secrets, updateSecrets ] = useState([])

  useEffect(() => {
    status.onSuccess(updateSecrets)
  }, [status])

  const append = (json) => updateSecrets(secrets.concat([json]))

  return (
    <Content.Provider value={{ status, secrets, append }}>
      {children}
    </Content.Provider>
  )
}