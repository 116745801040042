import React from 'react'

const accessor = props => (
  props.format 
    ? props.format(props.json)
    : props.json[props.field] ? props.json[props.field] : ''
)

export default ({ Component, ...props }) => (
  Component 
    ? <Component { ...props }>{accessor(props)}</Component>
    : <>{accessor(props)}</>
)