import React from 'react'
import { NonIdealState, Button, Intent, Card } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

export default () => (
  <Card elevation={2}>
    <NonIdealState
      icon={IconNames.UPDATED} 
      title="Please wait, quality analysis under way!"
      description={`
        Assay It has queued your assessment request.
        We delvers results as soon as possible.
      `}
      action={
        <Button
          large
          intent={Intent.PRIMARY}
          icon={IconNames.REFRESH}
          onClick={() => window.location.reload()}
        >
          Refresh
        </Button>
      }
    />
  </Card>
)

