import React from 'react'
import { Card, H2, H3, H4, Button, Intent, Text, Divider, Tag } from '@blueprintjs/core'
import { Flex, Box } from 'reflexbox'
import { upgradeBasic, upgradePersonal, upgradeTeam } from 'toolkit/account'
import './Subscription.css'
import { WhoIs } from 'react-hook-oauth2'
import { profile } from 'toolkit/account'

const Upgrade = ({profile, active, onUpgrade}) => {
  if (active === '') {
    return null
  }

  if (active === profile) {
    return (<Tag large intent={Intent.SUCCESS}>Active</Tag>)
  }

  return (<Button intent={Intent.PRIMARY} minimal onClick={onUpgrade}><b>Upgrade</b></Button>)
}

const UI = ({ scopes }) => {
  const active = profile(scopes)
  
  return (
  <div className="bp3-running-text subscription">
    <H2>Plan Options</H2>

    <H4>Start your Early Access free of charge. No credit card required.</H4>

    <Text>
      We run a quality assessment jobs on your behalf, the duration it takes contributes to the final cost. Early Access provides you <b>unlimited</b> access to quality assessment time <b>free of charge</b> for 6 month.      
      Sign Up with your GitHub account to enable quality assessment of <b>private</b> and <b>public</b> repositories. 
      You'll get <b>premium</b> support with Early Access. Our team is ready to support you with the service through Slack or Email.
    </Text>

  <Flex
    mt={['1em']}
    style={{justifyContent: 'space-around', alignItems: 'center'}}
  >
    <Box width={['30%']}>
      <Card interactive elevation={active === 'Basic' && 4}>
        <H3>Basic</H3>
        <Divider/>
        <p><strong>unlimited</strong> minutes</p>
        <p><strong>unlimited</strong> public repositories</p>
        <p><strong>30 days</strong> of data retention</p>
        <p>slack <strong>Community</strong> support</p>
    
        <Upgrade
          profile='Basic'
          active={active}
          onUpgrade={upgradeBasic}
        />
      </Card>
    </Box>
    <Box width={['30%']}>
      <Card interactive elevation={active === 'Personal' && 4}>
        <H3>Personal</H3>
        <Divider/>
        <p><strong>all</strong> Basic features</p>
        <p><strong>unlimited</strong> private repositories</p>
        <p><strong>90 days</strong> of data retention</p>
        <p>&nbsp;</p>

        <Upgrade
          profile='Personal'
          active={active}
          onUpgrade={upgradePersonal}
        />
      </Card>
    </Box>
    <Box width={['30%']}>
      <Card interactive elevation={active === 'Team' && 4}>
        <H3>Team</H3>
        <Divider/>
        <p><strong>all</strong> Personal features</p>
        <p><strong>unlimited</strong> teams and organizations</p>
        <p><strong>chat</strong> and <strong>email</strong> support</p>
        <p><strong>priority</strong> support</p>
        
        <Upgrade
          profile='Team'
          active={active}
          onUpgrade={upgradeTeam}
        />
      </Card>
    </Box>
  </Flex>
  </div>
  )
}

export default () => WhoIs(UI)

/*

Compare https://assay.it plans and learn everything you need for quality assessment of your product.


##
## pitch, why to subscribe
hero:
  image: /images/select_option.svg
  title: The quality is priceless...

*/
