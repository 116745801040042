import React from 'react'
import { useSecureLookup } from 'react-hook-oauth2'
import Header from './Head'
import HubSourceCode from './HubSourceCode'

const HOST = process.env.REACT_APP_API

export default () => {
  const { status, sequence } = useSecureLookup(`${HOST}/account/sourcecode`)
  const forceReSync = () => sequence(`${HOST}/account/sourcecode/sync`)

  return (
    <>
      <Header status={status} action={forceReSync} />
      <HubSourceCode status={status} />
    </>
  )
}