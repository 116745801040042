import React from 'react'
import { WhileIO, Pending, Success, Failure } from 'react-hook-oauth2'
import {
  HTMLTable,
  Icon,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { withRouter, Link } from 'react-router-dom'
import { Chips } from 'components/core/Status'
import { NoSourceCode, Issue } from 'components/core/Issue'
import { H2 } from 'components/core'
import moment from 'moment'
import './SourceCode.css'

const Hx = () => (
  <H2 icon={IconNames.CODE} title="Repositories">
    <Link
      to="/config/sourcecode"
      className="bp3-button bp3-minimal bp3-intent-primary"
    >
      <Icon icon={IconNames.ADD} />
    </Link>
  </H2>
)


const None = () => (
  <>
  <Hx />
  <HTMLTable interactive>
    <tbody>
      <tr>
        <td className="bp3-skeleton">Lorem ipsum dolor sit amet</td>
        <td className="bp3-skeleton">Lorem ipsum dolor sit amet</td>
        <td className="bp3-skeleton">Lorem ipsum dolor sit amet</td>
      </tr>
      <tr>
        <td className="bp3-skeleton">Lorem ipsum dolor sit amet</td>
        <td className="bp3-skeleton">Lorem ipsum dolor sit amet</td>
        <td className="bp3-skeleton">Lorem ipsum dolor sit amet</td>
      </tr>
      <tr>
        <td className="bp3-skeleton">Lorem ipsum dolor sit amet</td>
        <td className="bp3-skeleton">Lorem ipsum dolor sit amet</td>
        <td className="bp3-skeleton">Lorem ipsum dolor sit amet</td>
      </tr>
      <tr>
        <td className="bp3-skeleton">Lorem ipsum dolor sit amet</td>
        <td className="bp3-skeleton">Lorem ipsum dolor sit amet</td>
        <td className="bp3-skeleton">Lorem ipsum dolor sit amet</td>
      </tr>
    </tbody>
  </HTMLTable>
  </>
)

const Empty = () => (
  <>
    <Hx />
    <NoSourceCode />
  </>
)

const List = withRouter(({ content, history }) => (
      <>
      <div className="sourcecode">
      <Hx />

      </div>
      <HTMLTable interactive>
      <colgroup>
      <col style={{width: '15%'}} />
      <col style={{width: '50%'}} />
      <col style={{width: '35%'}} />
      </colgroup>
      <thead>
      <tr>
      <th>Status</th>
      <th>Name</th>
      <th>Last Build</th>
      </tr>
      </thead>
      <tbody>
      {content.map(item => (
        <tr
          key={item.id}
          style={{lineHeight: '48px', fontSize: '20px'}}
          onClick={() => 
            item.build
              ? history.push(item.build.split(':').join('/'))
              : history.push(`github/${item.owner}/${item.label}`)
          }
        >
          <td><Chips value={item.status}>{item.status}</Chips></td>
          <td><Icon iconSize={20} icon={item.private ? IconNames.SHIELD : IconNames.GIT_REPO}/>&nbsp;{item.title}</td>
          <td>{item.updated && moment(item.updated).fromNow()}</td>
        </tr>
      ))}
      </tbody>
      </HTMLTable>
      </>
))

export default WhileIO(
  Pending(None),
  Failure(Issue),
  Success(List, (seq) => seq && seq.length > 0),
  Success(Empty, (seq) => seq || seq.length === 0),
)
