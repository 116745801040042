import React from 'react'
import { Popover, Card, Button, Position, Intent} from '@blueprintjs/core'

const Confirm = () => (
  <Card>
    <p>
      Please <b>Confirm</b> the termination of your Assay It account.<br/>
      All your reports and other personal data will be <b>erase permanently</b>.<br/>
      This action <b>cannot be undone!</b>
    </p>
    <Button intent={Intent.DANGER}>Confirm</Button>
  </Card>
)

export default () => (
  <Popover
    position={Position.RIGHT}
    content={<Confirm />}
  >
    <Button intent={Intent.DANGER}>Unlink GitHub</Button>
  </Popover>
)